import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useState } from 'react';
import { Menu, X } from 'lucide-react';
import HomeSection from './HomeSection';
import TeamSection from './TeamSection';
import InitiativesSection from './InitiativesSection';
import StorySection from './StorySection';

// Available roles array
const AVAILABLE_ROLES = [
  'Developer',
  'Designer',
  'Project Manager',
  'Marketing Specialist',
  'Content Writer'
];

const NavLink = ({ to, children, onClick }) => (
  <Link 
    to={to} 
    onClick={onClick}
    className="text-gray-700 hover:text-purple-600 px-3 py-2 rounded-md text-sm font-medium"
  >
    {children}
  </Link>
);

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [contactInfo, setContactInfo] = useState({ name: '', email: '' });
  const [showContactForm, setShowContactForm] = useState(false);

  const handleJoinUsClick = () => {
    setShowRoleModal(true);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setShowContactForm(true);
    setShowRoleModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send this data to your backend
    console.log('Submission:', { ...contactInfo, role: selectedRole });
    // Reset and close forms
    setShowRoleModal(false);
    setShowContactForm(false);
    setContactInfo({ name: '', email: '' });
    setSelectedRole('');
  };

  return (
    <Router>
      <div className="min-h-screen bg-white">
        {/* Navigation */}
        <nav className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <Link to="/" className="text-2xl font-bold text-purple-600">
                  Shakti Learn
                </Link>
              </div>
              
              <div className="hidden md:flex items-center space-x-4">
                <NavLink to="/">Home</NavLink>
                <NavLink to="/team">Our Team</NavLink>
                <NavLink to="/impact">Impact</NavLink>
                <NavLink to="/story">Our Story</NavLink>
                <button onClick={handleJoinUsClick} className="bg-purple-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-purple-700">
                  Join Us
                </button>
              </div>

              <div className="md:hidden flex items-center">
                <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600">
                  {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden bg-white shadow-lg">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <NavLink to="/" onClick={() => setIsMenuOpen(false)}>Home</NavLink>
                <NavLink to="/team" onClick={() => setIsMenuOpen(false)}>Our Team</NavLink>
                <NavLink to="/impact" onClick={() => setIsMenuOpen(false)}>Impact</NavLink>
                <NavLink to="/story" onClick={() => setIsMenuOpen(false)}>Our Story</NavLink>
              </div>
            </div>
          )}
        </nav>

        {/* Main Content */}
        <Routes>
          <Route path="/" element={<HomeSection />} />
          <Route path="/team" element={<TeamSection />} />
          <Route path="/impact" element={<InitiativesSection />} />
          <Route path="/story" element={<StorySection />} />
        </Routes>

        {/* Role Selection Modal */}
        {showRoleModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
              <h2 className="text-xl font-bold mb-4">Select a Role</h2>
              <div className="space-y-2">
                {AVAILABLE_ROLES.map((role) => (
                  <button
                    key={role}
                    onClick={() => handleRoleSelect(role)}
                    className="block w-full p-2 text-left hover:bg-gray-100 rounded"
                  >
                    {role}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Contact Form Modal */}
        {showContactForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
              <h2 className="text-xl font-bold mb-4">Contact Information</h2>
              <p className="mb-4">Selected Role: {selectedRole}</p>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={contactInfo.name}
                  onChange={(e) => setContactInfo({ ...contactInfo, name: e.target.value })}
                  className="w-full p-2 border rounded"
                  required
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  value={contactInfo.email}
                  onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                  className="w-full p-2 border rounded"
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-purple-600 text-white p-2 rounded hover:bg-purple-700"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Router>
  );
}