import { BookOpen, Building, Users } from 'lucide-react';

export default function HomeSection() {
  return (
    <>
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-purple-50 to-pink-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Empowering Young Indian Women</span>
              <span className="block text-purple-600">Through Education</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Free personalized mentoring in English communication, digital skills, and professional development. Building pathways to economic independence.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button 
                  onClick={() => window.location.href = '#contact'}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                >
                  Start Learning
                </button>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <button 
                  onClick={() => window.location.href = '#mentor'}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Become a Mentor
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { number: "500+", label: "Students Enrolled" },
              { number: "200+", label: "Active Mentors" },
              { number: "15+", label: "NGO Partners" },
              { number: "85%", label: "Employment Rate" }
            ].map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-4xl font-bold text-purple-600">{stat.number}</div>
                <div className="text-sm text-gray-600 mt-1">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Programs Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Programs</h2>
            <p className="mt-4 text-lg text-gray-500">Comprehensive training designed for success</p>
          </div>

          <div className="mt-12 grid gap-8 md:grid-cols-3">
            {[
              {
                icon: <BookOpen className="h-6 w-6 text-purple-600" />,
                title: "English Language",
                description: "One-on-one virtual tutoring focused on business communication and industry-specific vocabulary."
              },
              {
                icon: <Building className="h-6 w-6 text-purple-600" />,
                title: "Digital Skills",
                description: "Computer literacy, software training, and basic coding skills for the modern workplace."
              },
              {
                icon: <Users className="h-6 w-6 text-purple-600" />,
                title: "Professional Development",
                description: "Career guidance, interview preparation, and workplace culture training."
              }
            ].map((program) => (
              <div key={program.title} className="bg-white rounded-lg shadow-lg p-6">
                <div className="w-12 h-12 rounded-md bg-purple-100 flex items-center justify-center mb-4">
                  {program.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{program.title}</h3>
                <p className="mt-4 text-gray-500">{program.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}