import React from 'react';
import zoieImage from './images/zoie_shaktilearn.jpeg';
import gunjanImage from './images/gunjan_shaktilearn.jpeg';
import samyaImage from './images/samya_shaktilearn.jpeg';
import raginiImage from './images/ragini_shaktilearn.jpeg';

export default function TeamSection() {
  return (
    <div className="min-h-screen bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Meet Our Team</h1>
        
        {/* Founder Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Founder</h2>
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <img src={zoieImage} alt="Zoie Kapur" className="rounded-lg shadow-lg object-cover w-full h-[400px]" />
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-4">Zoie Kapur</h3>
              <p className="text-gray-600 mb-4">Founder & CEO</p>
              <p className="text-gray-700 leading-relaxed mb-6">
                A high school student at Cranleigh Abu Dhabi, Zoie founded Shakti Learn in 2024. She recognised the need for a tool to bridge the opportunity gap for young women in India after realising the transformative power speaking English holds. She is committed to the cause and aims to one day fully bridge the opportunity gap through accessible education.
              </p>
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">Founded Shakti Learn in 2024</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">High School Student at Cranleigh Abu Dhabi</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">Passionate about educational equity</p>
                </div>
              </div>
            </div>
          </div>
        </div>

       {/* Core Team */}
<div className="mb-16">
  <h2 className="text-2xl font-semibold text-purple-600 mb-8">Core Team</h2>
  <div className="grid md:grid-cols-3 gap-8">
    {[
      {
        name: "Gunjan Yadav",
        role: "Program Lead",
        bio: "An English major at Shiv Nadar University who is incredibly passionate about writing. She uses her English skills to help the underprivileged and has been associated with Shakti Learn since its founding.",
        image: gunjanImage
      },
      {
        name: "Samya Gautam",
        role: "Professional Development Lead",
        bio: "An Economics major at Delhi University who uses her technical skills to help women improve their public speaking as part of their professional development with Shakti Learn. She has been with Shakti Learn since its creation in 2024.",
        image: samyaImage
      },
      {
        name: "Ragini Raghav",
        role: "Senior Educational Advisor",
        bio: "Ragini Raghav is a retired teacher with 26 years of experience, now dedicated to empowering underprivileged girls through education, helping them build brighter futures.",
        image: raginiImage
      }
    ].map((member) => (
      <div key={member.name} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
        <img src={member.image} alt={member.name} className="w-full h-48 object-cover" />
        <div className="p-6">
          <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
          <p className="text-purple-600 mb-4">{member.role}</p>
          <p className="text-gray-600">{member.bio}</p>
        </div>
      </div>
    ))}
  </div>
</div>

        {/* Join The Team Banner */}
        <div className="bg-purple-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Our Mission</h2>
          <p className="text-gray-600 mb-6">
            We're always looking for passionate individuals to join our team and help make a difference.
          </p>
          <button className="bg-purple-600 text-white px-8 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors duration-200">
            View Open Positions
          </button>
        </div>
      </div>
    </div>
  );
}